import { dataLayerPush } from '@chotot/web-gtm';

/**
 *
 * @param {*} param0
 */
export const asyncGTMClickEvent = async ({
  event = 'track_event',
  action = 'click',
  label,
  category = '',
  rest = {},
}) => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer && !Array.isArray(window.dataLayer)) window.dataLayer = [];
    window.dataLayer.push({
      event,
      event_action: action,
      event_category: category,
      event_label: label,
      ...rest,
      eventTimeout: 2000,
      eventCallback: () => Promise.resolve(),
    });
  }
};

/**
 *
 * @param {*} param0
 */
export const gtmEventClick = ({
  event = 'track_event',
  action = 'click',
  label = '',
  category = 'login',
  rest = {},
}) => {
  if (typeof window !== 'undefined') {
    dataLayerPush({
      event,
      event_action: action,
      event_category: category,
      event_label: label,
      ...rest,
    });
  }
};

/**
 *
 * @param {*} param0
 */
export const asyncGTMPageMetadata = async ({ page = '', event = 'page_metadata', rest = {} }) => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer && !Array.isArray(window.dataLayer)) window.dataLayer = [];
    window.dataLayer.push({
      event,
      page_type: page,
      ...rest,
      eventTimeout: 2000,
      eventCallback: () => Promise.resolve(),
    });
  }
};

/**
 *
 * @param {*} param0
 */
export const gtmCustomDemesion = async ({ event = 'page_metadata', rest = {} }) => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer && !Array.isArray(window.dataLayer)) window.dataLayer = [];
    window.dataLayer.push({
      event,
      ...rest,
      eventTimeout: 2000,
      eventCallback: () => Promise.resolve(),
    });
  }
};

/**
 *
 * @param {*} param0
 */
export const gtmPageMetadata = ({ page = '', event = 'page_metadata', rest = {} }) => {
  if (typeof window !== 'undefined') {
    dataLayerPush({
      event,
      page_type: page,
      ...rest,
    });
  }
};

export const genderToGA4Data = (gender) => {
  switch (gender) {
    case 'm':
      return 'male';
    case 'f':
      return 'female';
    case 'u':
      return 'genderless';
    default:
      return 'undefined';
  }
};
