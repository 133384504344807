import Cookies from 'js-cookie';
import config from '~/config';

const { cookieConfig } = config;

export const removeCookie = (key) => {
  Cookies.remove(key, { path: cookieConfig.path, domain: cookieConfig.domain });
};

export const getCookie = (key) => Cookies.get(key);

export const cookieOption = {
  expires: cookieConfig.expires,
  path: cookieConfig.path,
  domain: cookieConfig.domain,
};

export const setCookie = (key, value) => Cookies.set(key, value, cookieOption);
