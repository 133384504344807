import Cookies from 'js-cookie';
import { setCookie, cookieOption } from '~/helpers/api/Cookie';
import { getIdentifiers } from '~/helpers/util';

export const setCookiesByPhone = (data) => {
  setCookie('userId', btoa(data.profile.account_id));

  /* returning visitor */
  let returning = 1;

  if (
    typeof Cookies.get('rVisitor') === 'undefined' ||
    Cookies.get['user.returning'] === 'undefined'
  ) {
    returning = 0;
  }

  if (data.profile.start_time > data.profile.create_time) returning = 1;
  if (data.profile.update_time > data.profile.create_time) returning = 1;

  setCookie('u.r', String(returning));

  let loggedBy;
  if (data.profile.phone) {
    loggedBy = 1;
  }

  if (data.profile.facebook_id) {
    if (loggedBy === 1) {
      loggedBy = 3;
    } else {
      loggedBy = 2;
    }
    setCookie('u.fai', data.profile.facebook_id);
  }

  Cookies.set('loginIdentifier', data.loginIdentifier, {
    ...cookieOption,
    httpOnly: true,
  });

  setCookie('u.lb', String(loggedBy));
  setCookie('msess', data.msess);
  setCookie('loginMethod', getIdentifiers(data.profile));
};

export const setCookiesBySocial = (data) => {
  setCookie('loginMethod', getIdentifiers(data.profile));

  if (data.profile.account_id) {
    setCookie('userId', btoa(data.profile.account_id));
    Cookies.set('chapyToken', data.token, { ...cookieOption, httpOnly: false });
  }
};
